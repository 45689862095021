<template>
	<section>
		<b-card
			class="px-table-0 w-10-percent-cl-1 w-17-percent-cl-3 w-12-percent-cl-4 w-12-percent-cl-5 w-15-percent-cl-6 w-12-percent-cl-last w-12-percent-cl-last">
			<div class="text-right mb-2 mr-2">
				<template v-if="(userData && (permission ? permission.edit : false))">

					<b-button type="button" variant="primary" class="btn-df size-18 black"
						style="background-color: #8471FF !important" @click="addNewGift">
						Add New Gift
					</b-button>

				</template>
				<template v-else>
					<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
						Add New Gift
					</b-button>
				</template>
			</div>
			<b-table small :fields="fields_gift" :items="gift" tbody-tr-class="size-14 table-row" show-empty
				@head-clicked="headClicked">
				<template #head(id)="data">
					<span>{{ data.label }}</span>
					<span
						:class="['cursor-pointer iconsort', { sort: data.field.key == sort.field && sort.type == 'desc' }]"
						style="position: relative; left: 20px;">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2"
								stroke-linecap="round" stroke-linejoin="round" />
							<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round"
								stroke-linejoin="round" />
						</svg>
					</span>
				</template>
				<template #head()="data">
					<span class="d-block text-center">{{ data.label }}</span>
				</template>
				<template #head(description)="data">
					<span>{{ data.label }}</span>
				</template>
				<template #cell(id)="data">
					<span v-if="data.field.key == sort.field && sort.type == 'desc'">
						{{ total - data.index }}
					</span>
					<span v-else>
						{{ data.index + 1 }}
					</span>
				</template>
				<template #cell(description)="data">
					<span class="maxThreeRow">{{ data.item.description }}</span>
				</template>
				<template #cell(presented_to)="data">
					<span class="maxThreeRow text-center">{{ data.item.presented_to }}</span>
				</template>
				<template #cell(received_from)="data">
					<span class="maxThreeRow text-center">{{ data.item.received_from }}</span>
				</template>
				<template #cell(remarks)="data">
					<span class="maxThreeRow text-center">
						<template v-if="data.item.remarks == null || data.item.remarks == 'null'">
							-
						</template>
						<template v-else>
							{{ data.item.remarks }}
						</template>
					</span>
				</template>
				<template #cell(image_thumb)="data">
					<div class="text-center d-block">
						<b-img :src="data.item.image_thumb" v-if="data.item.image_thumb" style="width: 100px"></b-img>
					</div>
				</template>
				<template #cell(action)="data">
					<div class="d-block text-center">
						<template
							v-if="(userData && (permission ? permission.edit : false))">
							<span class="px-05 cursor-pointer"
								@click.stop="editGift(data.item.id, data.item.image, data.item.description, data.item.presented_to, data.item.received_from, data.item.remarks)">
								<svg width="19" height="19" viewBox="0 0 19 19" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
										fill="#8471FF" />
								</svg>
							</span>
							<span class="px-05 cursor-pointer"
								@click.stop="showDeleteAction(data.item.id, data.item.description)">
								<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd"
										d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
										fill="#DD3E47" />
								</svg>
							</span>
						</template>
						<template v-else>
							<span class="px-05">
								<svg width="19" height="19" viewBox="0 0 19 19" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
										fill="#b1b1b1" />
								</svg>
							</span>
							<span class="px-05">
								<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd"
										d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
										fill="#b1b1b1" />
								</svg>
							</span>
						</template>
					</div>
				</template>
				<template #cell(created_at)="data">
					<div class="d-block text-center">
						{{ data.item.created_at | formatDatebyMoment("YYYY-MM-DD hh:mm:ss", "DD/MM/YY") }}
					</div>
				</template>
				<template #cell()="data">
					<span class="d-block text-center">{{ data.value }}</span>
				</template>
				<template #empty="scope">
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
				</template>
			</b-table>
		</b-card>
		<div v-if="this.total > 0" class="paging-cus relative">
			<b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage" aria-controls="active-table"
				first-number last-number class="just-center"></b-pagination>
			<div class="limit-per">
				<select v-model="selected" class="cursor-pointer">
					<option v-for="limit in limitpage">{{ limit }}</option>
				</select>
			</div>
		</div>
		<b-modal id="addGift" ref="addGift_rel" title="Add New Gift" hide-header-close ok-title="Save" centered>
			<validation-observer ref="form_rel" #default="{ invalid }">
				<b-row style="padding-right: 60px">

					<b-col lg="12" class="px-0">
						<b-form-group label="File Upload" label-cols-xl="4" label-cols-md="3"
							label-class="text-lg-right bold" class="uploadImage star-required">
							<div class="upload-show-name container-box px-1" v-if="fieldAdd.file"
								style="flex-direction: column; word-break: break-all">
								<div class="close-upload cursor-pointer" @click="closeUpload">
									<feather-icon icon="XCircleIcon" size="24" color="#DD3E47" />
								</div>
								<p>
									<feather-icon icon="ImageIcon" size="30" />
								</p>
								{{ fieldAdd.file ? fieldAdd.file.name : '' }}
							</div>
							<div v-else class="bg container-box"
								:style="{ backgroundImage: `url(${require('@/assets/images/itees/admin/UploadFile.png')})` }">
								<validation-provider #default="{ errors }" name="File Upload" vid="file" rules="required">
									<b-form-file v-model="fieldAdd.file" plain accept=".jpg, jpeg, .png" ref="fileImage"
										class="cursor-pointer">
									</b-form-file>
									<small class="text-danger text-left d-block">{{ errors[0] }}</small>
								</validation-provider>
							</div>
						</b-form-group>
					</b-col>
					<b-col lg="12" class="px-0">
						<b-form-group label="Gift Description" label-cols-xl="4" label-cols-md="3"
							label-class="text-lg-right bold" class="star-required">
							<validation-provider #default="{ errors }" name="Gift Description" rules="required">
								<b-form-textarea v-model="fieldAdd.description" placeholder="Brief Description" rows='4'
									:formatter="length1000"></b-form-textarea>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col lg="12" class="px-0">
						<b-form-group label="Presented To" label-cols-xl="4" label-cols-md="3"
							label-class="text-lg-right bold" class="star-required input-group-label">
							<validation-provider #default="{ errors }" name="Presented To" rules="required">
								<b-form-input v-model="fieldAdd.presented" placeholder="Name" type="text" col-md="7"
									:formatter="length100"></b-form-input>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col lg="12" class="px-0">
						<b-form-group label="Received From" label-cols-xl="4" label-cols-md="3"
							label-class="text-lg-right bold" class="star-required input-group-label">
							<validation-provider #default="{ errors }" name="Received From" rules="required">
								<b-form-input v-model="fieldAdd.received" placeholder="Name" type="text" col-md="7"
									:formatter="length100"></b-form-input>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col lg="12" class="px-0">
						<b-form-group label="Remarks" label-cols-xl="4" label-cols-md="3" label-class="text-lg-right bold">
							<b-form-textarea v-model="fieldAdd.remark" placeholder="Remarks" rows='4'
								:formatter="length1000"></b-form-textarea>
						</b-form-group>
					</b-col>
				</b-row>
			</validation-observer>
			<template #modal-footer="{ cancel }">
				<b-button size="sm" variant="static" class="btn-default mb-10" @click="cancel()">
					Cancel
				</b-button>
				<b-button size="sm" variant="primary" class="btn-primary mb-10" @click="saveAction">
					Save
				</b-button>
			</template>
		</b-modal>
		<b-modal id="editGift" ref="editGift_rel" title="Edit Gift" hide-header-close ok-title="Save" centered>
			<validation-observer ref="form_edit_rel" #default="{ invalid }">
				<b-row style="padding-right: 60px">
					<b-col lg="12" class="px-0">
						<b-form-group label="File Upload" label-cols-xl="4" label-cols-md="3"
							label-class="text-lg-right bold" class="uploadImage star-required">
							<div class="upload-show-name container-box" v-if="fieldEditShow.file"
								:style="{ 'background-image': `url(${fieldEditShow.file})`, 'flex-direction': 'column', 'background-size': 'cover', 'background-position': 'center' }">
								<div class="close-upload cursor-pointer" @click="closeEditGifts"
									style="right: -25px; top: 0;">
									<feather-icon icon="XCircleIcon" size="24" color="#DD3E47" />
								</div>
								<template v-if="fieldEditShow.file.name">
									{{ fieldEditShow.file.name }}
								</template>
								<p v-else>
									<b-img :src="fieldEditShow.file" class="w-100 fade"></b-img>
								</p>
							</div>
							<div v-else>
								<validation-provider #default="{ errors }" name="File Upload" rules="required">
									<div class="bg container-box"
										:style="{ backgroundImage: `url(${require('@/assets/images/itees/admin/UploadFile.png')})` }">

										<b-form-file v-model="fieldEditShow.file" plain accept=".jpg, jpeg, .png"
											ref="fileImage" class="cursor-pointer">
										</b-form-file>
									</div>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</div>
						</b-form-group>
					</b-col>
					<b-col lg="12" class="px-0">
						<b-form-group label="Gift Description" label-cols-xl="4" label-cols-md="3"
							label-class="text-lg-right bold" class="star-required">
							<validation-provider #default="{ errors }" name="Gift Description" rules="required">
								<b-form-textarea v-model="fieldEditShow.description" placeholder="Brief Description"
									rows='4' :formatter="length1000"></b-form-textarea>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col lg="12" class="px-0">
						<b-form-group label="Presented To" label-cols-xl="4" label-cols-md="3"
							label-class="text-lg-right bold" class="star-required input-group-label">
							<validation-provider #default="{ errors }" name="Presented To" rules="required">
								<b-form-input v-model="fieldEditShow.presented" placeholder="Name" type="text" col-md="7"
									:formatter="length100"></b-form-input>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col lg="12" class="px-0">
						<b-form-group label="Received From" label-cols-xl="4" label-cols-md="3"
							label-class="text-lg-right bold" class="star-required input-group-label">
							<validation-provider #default="{ errors }" name="Received From" rules="required">
								<b-form-input v-model="fieldEditShow.received" placeholder="Name" type="text" col-md="7"
									:formatter="length100"></b-form-input>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col lg="12" class="px-0">
						<b-form-group label="Remarks" label-cols-xl="4" label-cols-md="3" label-class="text-lg-right bold">
							<b-form-textarea v-model="fieldEditShow.remarks" placeholder="Remarks" rows='4'
								:formatter="length1000"></b-form-textarea>
						</b-form-group>
					</b-col>
				</b-row>
			</validation-observer>
			<template #modal-footer="{ cancel }">
				<b-button size="sm" variant="static" class="btn-default mb-10" @click="cancel()">
					Cancel
				</b-button>
				<b-button size="sm" variant="primary" class="btn-primary mb-10" @click="saveEditGift">
					Save
				</b-button>
			</template>
		</b-modal>
	</section>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, integer, min } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
	components: {
		ValidationProvider,
		ValidationObserver,
	},
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			fields_gift: [
				{ key: 'id', label: 'No.' },
				{ key: 'image_thumb', label: 'Gift Image' },
				{ key: 'description', label: 'Gift Description' },
				{ key: 'presented_to', label: 'Presented to' },
				{ key: 'received_from', label: 'Received From' },
				{ key: 'remarks', label: 'Remarks' },
				{ key: 'created_at', label: 'Date' },
				{ key: 'action', label: 'Actions' },
			],
			gift: [],
			fieldAdd: {
				file: null,
				description: null,
				presented: null,
				received: null,
				remark: null,
			},
			fieldEditShow: {
				id: null,
				file: null,
				description: null,
				presented: null,
				received: null,
				remark: null,
			},
			sort: {
				field: 'id',
				type: 'asc'
			},
			perPage: 10,
			currentPage: 1,
			total: 0,
			limitpage: ['10', '20', '30'],
			selected: '10',
			permission_edit: null,
		}
	},
	props: {
		permission: {
			type: [Array, String, Number, Object],
		}
	},
	mounted() {
		this.selected = '10'
	},
	watch: {
		currentPage(val) {
			this.featctList()
		},
		selected(val) {
			if (val) {
				this.perPage = val
				this.featctList()
			}
		},
	},
	created() {
		// console.log(this.permission, 'fffffffffffffffffffffff')
		this.featctList()
		this.getPermission()
	},
	methods: {
		getPermission() {
			this.$store
				.dispatch('project/detailProjectList', {
					id: this.$route.params.project_id
				})
				.then(response => {
					this.permission_edit = response.data.data.permission_edit
				})
				.catch((error) => {
					console.log(error)
				})
		},
		featctList() {
			this.$store
				.dispatch('project/listProjectGifts', {
					filter: {
						"list_search": [
							{
								"field": "project",
								"keyword": this.$route.params.project_id
							}
						]
					},
					limit: this.perPage,
					page: this.currentPage,
					sort: this.sort,
				})
				.then(response => {
					this.gift = response.data.data.data
					this.total = response.data.data.total
					// console.log('gift list: ', response.data.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		closeUpload() {
			this.fieldAdd.file = null
		},
		addNewGift() {
			this.fieldAdd.file = null
			this.fieldAdd.description = null
			this.fieldAdd.presented = null
			this.fieldAdd.received = null
			this.fieldAdd.remark = null
			this.$refs['addGift_rel'].show()
		},
		saveAction() {
			this.$refs.form_rel.validate().then(success => {

				if (success) {

					if (this.fieldAdd.file != null) {
						let datas = {
							file_upload: this.fieldAdd.file,
							description: this.fieldAdd.description,
							presented_to: this.fieldAdd.presented,
							received_from: this.fieldAdd.received,
							remarks: this.fieldAdd.remark,
							gift_table_id: this.$route.params.project_id,
							gift_table_type: 'project',
						}
						// console.log('data: ', datas)
						this.$store
							.dispatch('project/addProjectGifts', datas)
							.then(response => {
								this.$refs['addGift_rel'].hide()
								this.$toast({
									component: ToastificationContent,
									props: {
										title: 'New Gift Added!',
										variant: 'success',
									},
								},
									{
										position: 'top-center'
									})
								this.featctList()
								this.fieldAdd.file = null
								this.fieldAdd.description = null
								this.fieldAdd.presented = null
								this.fieldAdd.received = null
								this.fieldAdd.remark = null
							})
							.catch((error) => {
								console.log(error)
								this.$toast({
									component: ToastificationContent,
									props: {
										title: error.response.data.message,
										variant: 'danger',
									},
								},
									{
										position: 'top-center'
									}
								)
							})
					}
					else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Please select a file to upload',
								variant: 'danger',
							},
						},
							{
								position: 'top-center'
							})
					}
				}
			})
		},
		editGift(id, img, des, presented, received, remarks) {
			this.$refs['editGift_rel'].show()
			this.fieldEditShow.description = des
			this.fieldEditShow.id = id
			this.fieldEditShow.file = img
			this.fieldEditShow.remarks = remarks
			this.fieldEditShow.presented = presented
			this.fieldEditShow.received = received
		},
		closeEditGifts() {
			this.fieldEditShow.file = null
		},
		saveEditGift() {
			this.$refs.form_edit_rel.validate().then(success => {

				if (success) {

					let datasEdit = {}
					if (this.fieldEditShow.file && this.fieldEditShow.file.name) {
						datasEdit = {
							file_upload: this.fieldEditShow.file,
							description: this.fieldEditShow.description,
							presented_to: this.fieldEditShow.presented,
							received_from: this.fieldEditShow.received,
							remarks: this.fieldEditShow.remarks,
						}
					}
					else {
						datasEdit = {
							description: this.fieldEditShow.description,
							presented_to: this.fieldEditShow.presented,
							received_from: this.fieldEditShow.received,
							remarks: this.fieldEditShow.remarks,
						}
					}
					this.$store.dispatch('project/editProjectGifts',
						{
							id: this.fieldEditShow.id,
							data: datasEdit,
						})
						.then(response => {
							this.$refs['editGift_rel'].hide()
							this.$toast(
								{
									component: ToastificationContent,
									props: {
										title: 'Gift Updated!',
										variant: 'success',
									},
								},
								{
									position: 'top-center'
								}
							)
							this.featctList()
						})
						.catch((error) => {
							console.log(error)
							this.$toast(
								{
									component: ToastificationContent,
									props: {
										title: error.response.data.message,
										variant: 'danger',
									},
								},
								{
									position: 'top-center'
								})
						})
				}
			})
		},
		showDeleteAction(id, title) {
			this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
				title: 'Delete',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Delete',
				cancelTitle: 'Cancel',
				cancelVariant: 'outline-danger',
				footerClass: 'p-2 modal-delete',
				hideHeaderClose: false,
				centered: true
			})
				.then(value => {
					if (value) {
						this.deleteItem(id, title)
					}
				})
				.catch(err => {
					// An error occurred
				})
		},
		deleteItem(id) {
			this.$store
				.dispatch('project/deleteProjectGifts', {
					id: id
				})
				.then(response => {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Gift has been deleted!',
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						}
					)
					this.featctList()
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						}
					)
				})
		},
		length1000(e) {
			return String(e).substring(0, 1000)
		},
		length100(e) {
			return String(e).substring(0, 100)
		},
		headClicked(key) {
			if (this.sort.field == key) {
				if (this.sort.type == 'asc') this.sort.type = 'desc'
				else this.sort.type = 'asc'
			} else {
				this.sort.field = key
				this.sort.type = 'desc'
			}
			this.featctList()
		},
	}
};

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "@/assets/scss/_ite_tab_table.scss";

.tab-pt-0 .px-table-0>.card-body {
	padding-top: 0;
}
</style>