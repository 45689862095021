<template>
	<section>

		<b-row style="justify-content: space-between" class="mb-2 align-center">
			<b-col lg="10">
				<Breadcrumb :labels="labels" :detailName="detailName" />
			</b-col>
			<template v-if="this.$route.name == 'our-portfolio-project-detail-edit-blank'">
				<template v-if="this.openButton">
					<b-col lg="2" class="text-right">
						<template v-if="userData && permission.delete">
							<b-button type="button" variant="danger" class="btn-df size-18 black" @click="showDeleteAction">
								Delete
							</b-button>
						</template>
					</b-col>
				</template>
			</template>
		</b-row>
		<b-row>
			<b-col md="12">
				<div>
					<b-tabs card class="caption-add table-custom" v-model="tabIndex">
						<b-tab title="Basic Info" class="overview-tab">
							<tabBasic />
						</b-tab>
						<b-tab title="Activity Listing" :disabled="disableTabOnAdd">
							<tabActivity :permission="permission" />
						</b-tab>
						<b-tab title="Gifts" :disabled="disableTabOnAdd">
							<tabGifts :permission="permission" />
						</b-tab>
						<b-tab title="Documents" class="tab-child tab-pt-0" :disabled="disableTabOnAdd">
							<tabDocument :permission="permission" />
						</b-tab>
						<b-tab title="Users" class="tab-child tab-pt-0" :disabled="disableTabOnAdd">
							<tabUsers :permission="permission" />
						</b-tab>
					</b-tabs>
				</div>
			</b-col>
		</b-row>
	</section>
</template>

<script>
import Breadcrumb from '../partial/Breadcrumb';
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, integer, min } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import tabBasic from './projectListing/basic/List'
import tabActivity from './projectListing/activity/List'
import tabGifts from './projectListing/gifts/List'
import tabDocument from './projectListing/document/List'
import tabUsers from './projectListing/users/List'
import globalAdmin from '../model/globalAdmin';
import {
	mapActions,
	mapState
} from 'vuex'
export default {
	mixins: [globalAdmin],
	components: {
		Breadcrumb,
		flatPickr,
		vSelect,
		ValidationProvider,
		ValidationObserver,

		tabBasic,
		tabActivity,
		tabGifts,
		tabDocument,
		tabUsers,
	},
	data() {
		return {
			searchQuery: null,
			labels: { "our-portfolio-project-detail": "" },
			userData: JSON.parse(localStorage.getItem('userData')),
			permission: [],
			isEditing: [],
			projectListBasic_form: {},
			userData: JSON.parse(localStorage.getItem('userData')),
			disableTabOnAdd: false,

			tabIndex: 0,
			detailName: { "our-portfolio-project-detail-edit-blank": "" },
			openButton: true,
		}
	},
	watch: {
		"$route": function (val) {
			if (val) {

				if (this.$route && this.$route.query && this.$route.query.tab) this.tabIndex = Number(this.$route.query.tab)

				if (val.name == 'our-portfolio-project-detail-add' || this.$route.name == 'our-portfolio-project-add') {
					this.disableTabOnAdd = true
				}
				else {
					this.disableTabOnAdd = false
				}

				if (this.$route.params) {
					if (this.$route.params.project_id && this.$route.name == 'our-portfolio-project-detail-edit-blank') {
						this.getNameProject()
					}
				}
			}
		},
		tabIndex: function (val) {
			if (val == 0) this.openButton = true
			else this.openButton = false
		},
		permissions: function (val) {
			this.permission = val
		}
	},
	created() {
		this.permission = this.checkPermissionOurPortfolio(this.userData, "Project", this.$route.params.project_id, "Project") ?? []
		if (this.$route && this.$route.query && this.$route.query.tab) this.tabIndex = Number(this.$route.query.tab)

		this.EditingFun()
		if (this.$route.params.id) this.fetchCountryID()

		if (this.$route.name) {
			if (this.$route.name == 'our-portfolio-project-detail-add' || this.$route.name == 'our-portfolio-project-add') {
				this.disableTabOnAdd = true
			}
			else {
				this.disableTabOnAdd = false
			}
		}
		if (this.$route.params) {
			if (this.$route.params.project_id && this.$route.name == 'our-portfolio-project-detail-edit-blank') {
				this.getNameProject()
			}
		}
	},
	computed: mapState({
		permissions: state => state.auth.permissionOurPortfolio,
	}),
	methods: {
		...mapActions({
			checkPermisionOurPortfolio: 'auth/checkPermisionOurPortfolio',
		}),
		onChangeDateStart() {
			this.isCheckStartChange += 1;
			if (this.isCheckStartChange !== 0) {
				this.isCheckChange = true;
			}
		},
		fetchCountryID() {
			this.$store
				.dispatch('country/fetchCountryID', this.$route.params.id)
				.then(response => {
					this.labels['our-portfolio-project-detail'] = response.data.data.name
				})
				.catch((error) => {
					console.log(error)
				})
		},
		validationForm() {
			this.$refs.projectList_frm.validate().then(success => {
				return success;
			})
		},
		EditingFun() {
			if (this.$route.name == 'our-portfolio-project-detail-edit-blank') {
				this.isEditing = true
			}
			else {
				this.isEditing = false
			}
		},
		showDeleteAction() {
			this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
				title: 'Delete',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Delete',
				cancelTitle: 'Cancel',
				cancelVariant: 'outline-danger',
				footerClass: 'p-2 modal-delete',
				hideHeaderClose: false,
				centered: true
			})
				.then(value => {
					if (value) {
						this.delete_project(this.$route.params.project_id, this.$route.meta.label)
					}
				})
				.catch(err => { })
		},
		delete_project(id, title) {
			this.$store
				.dispatch('project/deleteProjectItem', {
					id: id
				})
				.then(response => {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: title + ' Has been deleted!',
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						}
					)
					this.$router.push({ path: `/admin/our-portfolio/project/${this.$route.params.id}` })
				})
				.catch((error) => {
					console.log(error)
				})
		},
		getNameProject() {
			this.$store
				.dispatch('project/detailProjectList', {
					id: this.$route.params.project_id
				})
				.then(response => {
					this.detailName['our-portfolio-project-detail-edit-blank'] = response.data.data.name
				})
				.catch((error) => {
					console.log(error)
				})
		}
	}
};

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "@/assets/scss/_ite_tab_table.scss";

#modal1 .modal-dialog,
#modal2 .modal-dialog {
	max-width: 650px;
}

.gift-table table thead tr th:first-child {
	min-width: 61px
}

.gift-table table thead tr th:last-child {
	min-width: 110px
}

#modal1 .modal-dialog .modal-header,
#modal2 .modal-dialog .modal-header {
	border-bottom: 1px solid rgba(0, 0, 0, 0.7);
}

#modal1 .modal-dialog .row-container,
#modal2 .modal-dialog .row-container {
	padding-right: 80px;
	padding-left: 50px;
}

#modal1 .modal-dialog .modal-body,
#modal2 .modal-dialog .modal-body {
	padding-top: 30px;
	padding-bottom: 0;
}

#modal1 .modal-dialog .modal-footer,
#modal2 .modal-dialog .modal-footer {
	padding-bottom: 30px;
}

.tab-pt-0 .px-table-0>.card-body {
	padding-top: 0;
}</style>