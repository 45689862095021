<template>
	<section>
		<div class="d-flex align-center white-bg pt-2">
	        <b-col lg="6" style="padding-left: 33px ">
	            <h2 class="mb-2"><strong>Basic Info</strong></h2>
	        </b-col>
	        <b-col lg-6 class="pr-2">	        		
	        	<div class="text-right mb-2">
	                <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAction" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

	                <template v-if="(userData)">

		                <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAction">
		                    Save
		                </b-button>

		            </template>
		            <template v-else>
		            	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
		                    Save
		                </b-button>
		            </template>
	            </div>							                
	        </b-col>
	    </div>				                
	    <div style="border-top: 1px solid #000000B2;" class="pb-2 white-bg"></div>
		<validation-observer
	        ref="form_rel"
	        #default="{invalid}" >
	        <b-form @submit.prevent class="profile-frm mb-20" style="border-radius: 5px">
	            <div class="frm-basic size-16 black-child d-block">
	              	<b-row style="width: 100%; margin: 0;">   
	                  	<b-col lg="6" class="px-0"> 
	                      	<b-form-group label="Project Name" label-cols-lg="4" class="star-required input-group-label">  
	                      		<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Name"
	                              	rules="required"                                  
	                            >                          
	                                <b-form-input
	                                	v-model="fields.name"
	                                    placeholder="Project Name"
	                                    :formatter="length100"
	                                ></b-form-input>
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                      	</b-form-group>
	                  	</b-col>
	                  	<b-col lg="6" class="px-0">
	                      	<b-form-group label="Project Code" label-cols-lg="4" class="star-required">
	                      		<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Code"
	                              	rules="required"                          
	                            >      
	                                <v-select
	                                	v-model="selectedCode"
	                                    label="code"
	                                    :options="projectCode"
	                                    placeholder="Project Code"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                     	</b-form-group>
	                  	</b-col>  
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Country" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="selectedCountry"
	                              	rules="required"
	                            >     
	                            	<template>
	                            		<v-select
		                                	v-model="selectedCountry"
		                                    label="name"
		                                    :options="selectCountry"
		                                    placeholder="Select Country"
		                                />
	                            	</template>	                                
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>         
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Province/State/City" label-cols-lg="4" class="star-required input-group-label">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Province / State / City"
	                              	rules="required"                                  
	                            >      
									<b-form-input
	                                	v-model="fields.city"
	                                    placeholder="Province/ State/ City"
	                                    required
	                                    :formatter="length50"
	                                ></b-form-input>
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>         
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Project Owner" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Owner"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedOwner"
	                                    label="full_name"
	                                    :options="selectOwner"
	                                    placeholder="Select Project Owner"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>         
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Project Status" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Status"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedStatus"
	                                    label="title"
	                                    :options="selectStatus"
	                                    placeholder="Select Status"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col> 
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Client" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Client"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedClient"
	                                    label="name"
	                                    :options="selectClient"
	                                    placeholder="Select Organisation"
	                                />	
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>               
	                        </b-form-group>
	                  	</b-col>      
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Partner" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Partner"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedPartner"
	                                    label="name"
	                                    :options="selectPartner"
	                                    placeholder="Select Organisation"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>      
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Project Value" label-cols-lg="4" class="star-required input-group-label">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Value"
	                              	rules="required"                                  
	                            >      
							        <b-form-input
					                  	v-model="projectValue"
										type="text"
					                  	placeholder="8,888.00"
										@keypress="validateNumber"
										@change="changeProjectValue"
										@focus="focusProjectValue"
										@blur="onRootLostFocus"
										:formatter="length20"
					                ></b-form-input>
	                                <small v-if="!isNumber" class="text-danger">Number is Invalid and required.</small>
									<small v-else class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>   
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Currency" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Currency"
	                              	rules="required"                                  
	                            >      	
	                                <v-select
	                                	v-model="selectedCurrency"
	                                    label="value"
	                                    :options="selectCurrency"
	                                    placeholder="SGD"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="12" class="px-0">
	                  		<div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
	                        <b-form-group label="Project Description" label-cols-lg="2" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Description"
	                              	rules="required"                                  
	                            >    
		                        	<b-form-textarea
		                        		v-model="fields.description"
		                                placeholder="Brief Description"
		                                rows="3"
		                                :formatter="length1000"
		                            ></b-form-textarea>
		                            <small class="text-danger">{{ errors[0] }}</small>
		                        </validation-provider>
	                        </b-form-group>
	                        <div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
	                  	</b-col>  
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Start Date" label-cols-lg="4" class="star-required">
	                        	<div class="calendarIcon">
	                        		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
	                        	</div>
	                        	<flat-pickr
									v-model="fields.start_date"
									class="form-control fieldCalendar"           
									:config="{ altInput: true , altFormat: 'd/m/y', dateFormat: 'Y-m-d'}"
									placeholder="Select a date"
									required
								/>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="End Date" label-cols-lg="4" class="star-required">
	                        	<div class="calendarIcon">
	                        		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
	                        	</div>
	                        	<flat-pickr
									v-model="fields.end_date"
									class="form-control fieldCalendar"           
									:config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d'}"
									placeholder="Select a date"
									required
								/>
	                        </b-form-group>
	                  	</b-col> 	       
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Start Date Remarks" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Start Date Remarks"
	                              	rules="required"                                  
	                            >   
		                        	<b-form-textarea
		                        		v-model="fields.start_remark"
		                                placeholder="Brief Description"
		                                rows="3"
		                                :formatter="length1000"
		                            ></b-form-textarea>
		                            <small class="text-danger">{{ errors[0] }}</small>
		                        </validation-provider>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="End Date Remarks" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="End Date Remarks"
	                              	rules="required"                                  
	                            >   
		                        	<b-form-textarea
		                        		v-model="fields.end_remark"
		                                placeholder="Brief Description"
		                                rows="3"
		                                :formatter="length1000"
		                            ></b-form-textarea>
		                            <small class="text-danger">{{ errors[0] }}</small>
		                        </validation-provider>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="12" class="px-0">
	                  		<div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
	                  	</b-col>  			                          	
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Leaders Trained (Direct)" label-cols-lg="4" class="label-break input-group-label">	                        	 
                            	<b-form-input
                                    placeholder="0"
                                    disabled
                                ></b-form-input>
	                        </b-form-group>
	                  	</b-col> 	     
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Specialists Trained (Direct)" label-cols-lg="4" class="input-group-label">
                            	<b-form-input
                                    placeholder="0"
                                    disabled
                                ></b-form-input>
	                        </b-form-group>
	                  	</b-col> 				                          	
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Leaders Trained (Indirect)" label-cols-lg="4" class="label-break input-group-label">
                            	<b-form-input
                                    placeholder="0"
                                    disabled
                                ></b-form-input>
	                        </b-form-group>
	                  	</b-col> 	     
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Specialists Trained (Indirect)" label-cols-lg="4" class="input-group-label">
                            	<b-form-input
                                    placeholder="0"
                                    disabled
                                ></b-form-input>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="12" class="px-0">
	                  		<div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
	                  	</b-col>   
	                  	<b-col lg="12" class="px-0">
	                        <b-form-group label="Project Remarks" label-cols-lg="2">
	                        	<b-form-textarea
	                        		v-model="fields.project_remark"
	                                placeholder="Remarks"
	                                rows="3"
	                                :formatter="length1000"
	                            ></b-form-textarea>
	                        </b-form-group>
	                  	</b-col>  
	              	</b-row>  
	            </div>
	        </b-form> 
	    </validation-observer>	
	    <b-col class="export white-bg pt-2 px-0" style="border-radius: 5px">
	    	<b-row class="align-center">
	            <b-col lg="6" style="padding-left: 33px ">
	                <h2 class="mb-2">Project Experts</h2>
	            </b-col>
	            <b-col lg-6>
	            	<div class="text-right mb-2 pr-2">
	            		<b-button type="button" class="btn-df size-18 black" disabled>
	                        Add Expert
	                    </b-button>
	                </div>
	            </b-col>
	        </b-row>	
	        <b-table small :fields="fields" :items="items" responsive="lg" tbody-tr-class="size-14 table-row" id="export-table" show-empty>
			    <template #head(action)="data">
			        <span class="d-block text-center">{{ data.label }}</span>
			    </template>
			    <template #head(fullname)="data">
			        <span class="d-block text-left">{{ data.label }}</span>
			    </template>
			    <template #cell(fullname)="data">
			        <span class="d-block text-left">{{ data.item.fullname }}</span>
			    </template>
			    <template #cell(id)="data">
					{{ data.index + 1 }}
				</template>									
				<template #cell(action)="data">
					<span class="d-block text-center cursor-pointer" @click="deteleItem(data.item.email)">
						<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#B1B1B1"/>
						</svg>
						<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#DD3E47"/>
						</svg>
					</span>
				</template>
				<template #cell()="data">
					<span class="d-block text-center">{{ data.value }}</span>
				</template>
				<template #empty="scope">
			      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
			    </template>
	        </b-table>
	    </b-col>
		<b-modal ref="my-modal" hide-footer title="Unsaved changes" size="lg">
			<div class="d-block">
				<span>You are about to leave this page without saving. All changes will be lost. Do you really want to leave without saving?</span>
			</div>
			<div style="display: flex; justify-content: flex-end; margin-top: 16px">
				<b-button  class="mr-1" variant="outline-danger" @click="hideModalWarning"><span>Leave without saving</span></b-button>
				<b-button  variant="danger" @click="saveChangeModalWarning"><span>Save changes</span></b-button>
			</div>
   		 </b-modal>
	</section>
</template>

<script>

	const moment = require('moment')
	import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
	  	components: {
	  		flatPickr,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
			    fields: {
			    	name: null,
			    	city: null,
			    	project_value: 0,
			    	description: null, 
			    	start_date: null,
			    	end_date: null,
			    	start_remark: null, 
			    	end_remark: null, 
			    	project_remark: null,  
			    },
			    projectCode: [],
			    codetam: [],
			    selectedCode: null,

			    selectCountry: [], 
			    selectedCountry: {
			    	name: 'Select Country',
			    	id: null
			    },

			    selectOwner: [],
                selectedOwner: null,

			    selectStatus: [
		            {title: 'Active ', value: 0},  
		            {title: 'Completed', value: 1},          
		            {title: 'Developing', value: 2},
		            {title: 'Terminated', value: 3},
		        ],
		        selectedStatus: null,

		        selectClient: [],
		        selectedClient: null,
		        
		        selectPartner: [],
		        selectedPartner: null,

		        selectCurrency: [],
		        selectedCurrency: null,

		        tab_number: null,
		        // export
                fields: [
                	{key: 'id', label: 'ID'},
                	{ key: 'fullname', label: 'Fullname' },
			        { key: 'email', label: 'Email' },			        
			        { key: 'status', label: 'Status' },
			        { key: 'action', label: 'Actions' },
			    ],
		    	items: [],	
		    	number_SGD: {
		    		numeral: true,
          			numeralThousandsGroupStyle: 'thousand',
		    	} ,
				//check number
				isNumber: true,

				projectValue: null,
            }
		},
		computed: {
	        mintoday(){            
	            var d = new Date()
	            d.setDate(d.getDate())
	            var now = moment(d).format('YYYY-MM-DD')
	            return now
	        },
	    },	
		created(){
			this.getProjectCode()
			this.listAllCountry()
			this.listOwner()
			this.listClient()
			this.listPartner()
			this.listCurrency()
			if(this.$route.params.id) { 
				this.getCountrybyID() 
			}
			else {
				this.selectedCountry = null
			}
		},
		methods: {
			length1000(e){
	            return String(e).substring(0,1000)
	        },
	        length100(e){
	            return String(e).substring(0,100)
	        },
	        length20(e){
	            return String(e).substring(0,20)
	        },
	        length50(e){
	            return String(e).substring(0,50)
	        },
			validateNumber(event) {
				const charCode = String.fromCharCode(event.keyCode);
				if (!/[0-9]/.test(charCode) && charCode && event.keyCode != 13 ) {
					event.preventDefault();
					this.isNumber = false;
				}else{
					this.isNumber = true;
				}
			},
			changeProjectValue(event) {
				if(event){
					this.projectValue = +event;
				}
			},
			onRootLostFocus(event) {
				if(this.projectValue){
					var number = +this.projectValue;
					this.projectValue = new Intl.NumberFormat('en-US',{
						style: 'currency',
						currency: 'USD'
					}).format(number)
					this.projectValue = this.projectValue.replace('$', '')
				}
			},
			focusProjectValue(event) {
				if(this.projectValue){
					this.projectValue = this.projectValue.split(',').join("");
					this.projectValue = this.projectValue.split('.00').join("");
				}
			},
			cancelAction(){
				if( this.fields.name !== undefined ||
			        this.fields.description !== undefined ||
			        this.selectedCountry !== null ||
			        this.fields.city !== undefined ||
			        this.selectedStatus !== null ||
			        this.fields.project_value !== undefined ||
			        this.selectedOwner !== null || 
			        this.fields.project_remark !== undefined ||
			        this.fields.start_date !== undefined ||
			        this.fields.start_remark !== undefined ||
			        this.fields.end_date !== undefined ||
			        this.fields.end_remark !== undefined ||
			        this.selectedCurrency !== null ||
			        this.selectedCode !== null ||
			        this.selectedClient !== null ||
			        this.selectedPartner !== null) {
					this.$refs['my-modal'].show()
				}
				else {
					if(this.$route.params && this.$route.params.id) {
						this.$router.push({ path: `/admin/our-portfolio/project/${this.$route.params.id}` })
					}
					else {
						this.$router.push({ name: 'our-portfolio-project' })
					}
				}
			},
			hideModalWarning() {
				this.$refs['my-modal'].hide()
				if(this.$route.params && this.$route.params.id) {
						this.$router.push({ path: `/admin/our-portfolio/project/${this.$route.params.id}` })
					}
					else {
						this.$router.push({ name: 'our-portfolio-project' })
				}
			},
			saveChangeModalWarning() {
				this.$refs['my-modal'].hide()
				this.$refs.form_rel.validate().then(success => {
                	if(success){
                		if(this.selectedCountry.id){
							this.projectValue = this.projectValue.split(',').join("");
							this.projectValue = this.projectValue.split('.00').join("");
	                		let list = {
			                    name: this.fields.name,
			                    description: this.fields.description,
			                    country_id: this.selectedCountry.id,
			                    province_state_city: this.fields.city,
			                    status: this.selectedStatus.value,
			                    amount: this.projectValue, //project value
			                    user_id: this.selectedOwner.id, //owner
			                    remarks: this.fields.project_remark,
			                    commencement_date: this.fields.start_date,
			                    commencement_remark: this.fields.start_remark,
			                    completion_date: this.fields.end_date,
			                    completion_remark: this.fields.end_remark,
			                    currency_name: this.selectedCurrency.value,
			                    project_code: this.selectedCode.id,
			                    client: this.selectedClient.id,
			                    partner: this.selectedPartner.id,
			                }
			                // console.log('data: ', list)
			                if(this.selectedStatus.value == 0){
			                	this.tab_number = 1
			                }
			                else if(this.selectedStatus.value == 1){
			                	this.tab_number = 2
			                }
			                else if(this.selectedStatus.value == 2){
			                	this.tab_number = 3
			                }
			                else if(this.selectedStatus.value == 3){
			                	this.tab_number = 4
			                }
			                this.$store
			                .dispatch('project/addProjectList', list)
			                .then(response => {
			                    this.$toast({
			                        component: ToastificationContent,
			                        props: {
			                            title: this.fields.name + ' Created!',
			                            variant: 'success',
			                        },
			                    },
			                    {
			                        position: 'top-center'
			                    })
			                    if(this.$route.params && this.$route.params.id) {
			                    	
			                    	this.$router.push({ path: '/admin/our-portfolio/project/' + this.selectedCountry.id, query: { tab: this.tab_number }})
			                    }
			                    else {
			                    	this.$router.push({ name: 'our-portfolio-project', query: { tab: this.tab_number } })
			                	}
			                })
			                .catch((error) => {
			                  	console.log(error)
			                  	this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                        	title: error.response.data.message,
			                        	variant: 'danger',
			                      	},
			                    },
			                    {
			                      position: 'top-center'
			                    })
			                })  
			            }
			            else{
			            	this.$toast({
			                    component: ToastificationContent,
			                    props: {
			                        title: 'Please select Country',
			                        variant: 'danger',
			                      },
			                },
			                {
			                      position: 'top-center'
			                })
			            }
                	}
                	else{
		            	 this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
		            }
              	})
			},
			saveAction(){
				this.$refs.form_rel.validate().then(success => {
                	if(success){
                		if(this.selectedCountry.id){
							this.projectValue = this.projectValue.split(',').join("");
							this.projectValue = this.projectValue.split('.00').join("");
	                		let list = {
			                    name: this.fields.name,
			                    description: this.fields.description,
			                    country_id: this.selectedCountry.id,
			                    province_state_city: this.fields.city,
			                    status: this.selectedStatus.value,
			                    amount: this.projectValue, //project value
			                    user_id: this.selectedOwner.id, //owner
			                    remarks: this.fields.project_remark,
			                    commencement_date: this.fields.start_date,
			                    commencement_remark: this.fields.start_remark,
			                    completion_date: this.fields.end_date,
			                    completion_remark: this.fields.end_remark,
			                    currency_name: this.selectedCurrency.value,
			                    project_code: this.selectedCode.id,
			                    client: this.selectedClient.id,
			                    partner: this.selectedPartner.id,
			                }
			                // console.log('data: ', list)
			                if(this.selectedStatus.value == 0){
			                	this.tab_number = 1
			                }
			                else if(this.selectedStatus.value == 1){
			                	this.tab_number = 2
			                }
			                else if(this.selectedStatus.value == 2){
			                	this.tab_number = 3
			                }
			                else if(this.selectedStatus.value == 3){
			                	this.tab_number = 4
			                }
			                this.$store
			                .dispatch('project/addProjectList', list)
			                .then(response => {
			                    this.$toast({
			                        component: ToastificationContent,
			                        props: {
			                            title: this.fields.name + ' Created!',
			                            variant: 'success',
			                        },
			                    },
			                    {
			                        position: 'top-center'
			                    })
			                    if(this.$route.params && this.$route.params.id) {
			                    	
			                    	this.$router.push({ path: '/admin/our-portfolio/project/' + this.selectedCountry.id, query: { tab: this.tab_number }})
			                    }
			                    else {
			                    	this.$router.push({ name: 'our-portfolio-project', query: { tab: this.tab_number } })
			                	}
			                })
			                .catch((error) => {
			                  	console.log(error)
			                  	this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                        	title: error.response.data.message,
			                        	variant: 'danger',
			                      	},
			                    },
			                    {
			                      position: 'top-center'
			                    })
			                })  
			            }
			            else{
			            	this.$toast({
			                    component: ToastificationContent,
			                    props: {
			                        title: 'Please select Country',
			                        variant: 'danger',
			                      },
			                },
			                {
			                      position: 'top-center'
			                })
			            }
                	}
                	else{
		            	 this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
		            }
              	})
			},

			getProjectCode(){
				this.$store
				.dispatch('auth/fetchProjectCodeList', {	
				})
				.then(response => {
					this.codetam = response.data.data
					this.projectCode = this.codetam.map(
		                (item) => {
		                	return{
		                		id: item.id,
		                		code: item.code
		                	}		                	
		                }
		              )
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listAllCountry(){
				this.$store
				.dispatch('auth/fetchCountry',{
					sort: {
						"field": "name",
						"type": "asc"
					}
				})
				.then(response => {
					this.selectCountry = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listOwner(){
				this.$store
				.dispatch('auth/fetch_staff_log_list', {
		            filter: {
		                "group":"1",
						"active":"1"
		            }
		        })
				.then(response => {
					this.selectOwner = response.data.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listClient(){
				this.$store
				.dispatch('auth/Client_Organisation_List', {
		            filter: { 
		            	"list_search": [{ "field": "client","keyword": "1"}]
		        	}
		        })
				.then(response => {
					this.selectClient = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listPartner(){
				this.$store
				.dispatch('auth/Client_Organisation_List', {
		            filter: { 
		            	"list_search": [{ "field": "partner","keyword": "1"}]
		        	}
		        })
				.then(response => {
					this.selectPartner = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listCurrency(){
				this.$store
				.dispatch('project/getCurrencyList')
				.then(response => {
					this.selectCurrency = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getCountrybyID(){				
				this.$store
				.dispatch('country/fetchCountryID', this.$route.params.id)
				.then(response => {
					this.selectedCountry.name = response.data.data.name
					this.selectedCountry.id = parseInt(this.$route.params.id)
				})
				.catch((error) => {
					console.log(error)
				})
			},
		},
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}	
</style>