<template>
	<section>
		<template v-if="this.$route.name == 'our-portfolio-project-detail-edit-blank'">
			<editProject />
	    </template>
	    <template v-else-if="this.$route.name == 'our-portfolio-project-detail-add' || this.$route.name == 'our-portfolio-project-add'">
			<addProject />
	    </template>
	</section>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    import addProject from './Add'
    import editProject from './Edit'

	export default {
	  	components: {
          	addProject,
          	editProject
	  	},
	  	data() {
		    return {
		    	searchQuery: null,
				labels: { "our-portfolio-project-detail-edit-blank": "" },
            }
		},	
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";

	.tab-pt-0 .px-table-0 > .card-body {
	    padding-top: 0;
	}
</style>