<template>
	<section>
		<div class="d-flex align-center white-bg pt-2">
	        <b-col lg="6" style="padding-left: 33px ">
	            <h2 class="mb-2"><strong>Basic Info</strong></h2>
	        </b-col>
	        <b-col lg-6 class="pr-2">	        					            	
	        	<div class="text-right mb-2">
	        		<b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAction" v-if="!isEditing" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>
	                <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAction" v-if="!isEditing">
	                    Save
	                </b-button>	                
	                <template v-else-if="isEditing">
		                <template v-if="userData && permission.edit">
		        			<b-button type="button" variant="primary" class="btn-df size-18 black"  @click="editAction">
			                    Edit
			                </b-button>
		        		</template>
		        		<template v-else>
		        			<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
			                    Edit
			                </b-button>
		        		</template>	               
		        	</template>
		        	
	            </div>						                
	        </b-col>
	    </div>				                
	    <div style="border-top: 1px solid #000000B2;" class="pb-2 white-bg"></div>
		<validation-observer
	        ref="form_rel"
	        #default="{invalid}" >
	        <b-form @submit.prevent class="profile-frm mb-20" style="border-radius: 5px">
	            <div class="frm-basic size-16 black-child d-block">
	              	<b-row style="width: 100%; margin: 0;">   
	                  	<b-col lg="6" class="px-0"> 
	                      	<b-form-group label="Project Name" label-cols-lg="4" class="star-required input-group-label">  
	                      		<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Name"
	                              	rules="required"                                  
	                            >                          
	                                <b-form-input
	                                	v-model="detail.name"
	                                    placeholder="Project Name"
										@change="onChange($event)"
	                                    :formatter="length100"
	                                    :disabled="isDisabled"
	                                ></b-form-input>
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                      	</b-form-group>
	                  	</b-col>
	                  	<b-col lg="6" class="px-0">
	                      	<b-form-group label="Project Code" label-cols-lg="4" class="star-required">
	                      		<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Code"
	                              	rules="required"                          
	                            >      
	                                <v-select
	                                	v-model="detail.project_code"
	                                    label="code"
	                                    :options="projectCode"
	                                    placeholder="Project Code"
										@input="change"
										:disabled="isDisabled"
                              			:id="disableField"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                     	</b-form-group>
	                  	</b-col>  
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Country" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="selectedCountry"
	                              	rules="required"
	                            >     
	                            	<template>
	                            		<v-select
		                                	v-model="selectedCountry"
		                                    label="name"
		                                    :options="selectCountry"
		                                    placeholder="Select Country"
											@input="change"
											:disabled="isDisabled"
                              				:id="disableField"
		                                />
	                            	</template>	                                
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>         
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Province/State/City" label-cols-lg="4" class="star-required input-group-label">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Province / State / City"
	                              	rules="required"                                  
	                            >      
	                            	<b-form-input
	                            		v-model="detail.province_state_city"
	                                    placeholder="Province/ State/ City"
										@change="onChange($event)"
										:formatter="length50"
										:disabled="isDisabled"
	                                ></b-form-input>
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>         
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Project Owner" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Owner"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedOwner"
	                                    label="full_name"
	                                    :options="selectOwner"
	                                    placeholder="Select Project Owner"
										@input="change"
										:disabled="isDisabled"
                              			:id="disableField"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>         
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Project Status" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Status"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedStatus"
	                                    label="title"
	                                    :options="selectStatus"
	                                    placeholder="Select Status"
										@input="change"
										:disabled="isDisabled"
                              			:id="disableField"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col> 
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Client" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Client"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedClient"
	                                    label="name"
	                                    :options="selectClient"
	                                    placeholder="Select Organisation"
										@input="change"
										:disabled="isDisabled"
                              			:id="disableField"
	                                />	
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>               
	                        </b-form-group>
	                  	</b-col>      
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Partner" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Partner"
	                              	rules="required"                                  
	                            >      
	                                <v-select
	                                	v-model="selectedPartner"
	                                    label="name"
	                                    :options="selectPartner"
	                                    placeholder="Select Organisation"
										@input="change"	
										:disabled="isDisabled"
                              			:id="disableField"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>      
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Project Value" label-cols-lg="4" class="star-required input-group-label">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Project Value"
	                              	rules="required"                                  
	                            >     
	                                <b-form-input
					                  	v-model="projectValue"
					                  	type="text"
					                  	placeholder="8,888.00"
										@keypress="validateNumber"
										@change="changeProjectValue"
										@focus="focusProjectValue"
										@blur="onRootLostFocus"
										:formatter="length20"
										:disabled="isDisabled"
					                ></b-form-input>
									<small v-if="!isNumber" class="text-danger">Number is Invalid and required.</small>
									<small v-else class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col>   
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Currency" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Currency"
	                              	rules="required"                                  
	                            >      	
	                                <v-select
	                                	v-model="detail.currency_name"
	                                    label="value"
	                                    :options="selectCurrency"
	                                    placeholder="SGD"
										@input="change"
										:disabled="isDisabled"
                              			:id="disableField"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="12" class="px-0">
	                  		<div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
	                        <b-form-group label="Project Description" label-cols-lg="2">
	                        	<b-form-textarea
	                        		v-model="detail.description"
	                                placeholder="Brief Description"
	                                rows="3"
	                                :formatter="length1000"
									@change="onChange($event)"
									:disabled="isDisabled"
	                            ></b-form-textarea>
	                        </b-form-group>
	                        <div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
	                  	</b-col>  
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Start Date" label-cols-lg="4" class="star-required">
	                        	<div class="calendarIcon">
	                        		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
	                        	</div>
								<flat-pickr
									v-model="detail.commencement_date"
									class="form-control fieldCalendar"           
									:config="{ altInput: true , altFormat: 'd/m/y', dateFormat: 'Y-m-d' }"
									placeholder="Select a date"
									:disabled="isDisabled"
									required
									@on-change="onChangeDateStart"
								/>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="End Date" label-cols-lg="4" class="star-required">
								<div class="calendarIcon">
	                        		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
	                        	</div>
								<flat-pickr
									v-model="detail.completion_date"
									class="form-control fieldCalendar"           
									:config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d'}"
									placeholder="Select a date"
									:disabled="isDisabled"
									required
									@on-change="onChangeDateEnd"
								/>
	                        </b-form-group>
	                  	</b-col> 	       
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Start Date Remarks" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="Start Date Remarks"
	                              	rules="required"                                  
	                            >   
		                        	<b-form-textarea
		                        		v-model="detail.commencement_remark"
		                                placeholder="Brief Description"
		                                rows="3"
		                                :formatter="length1000"
										@change="onChange($event)"
										:disabled="isDisabled"
		                            ></b-form-textarea>
		                            <small class="text-danger">{{ errors[0] }}</small>
		                        </validation-provider>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="End Date Remarks" label-cols-lg="4" class="star-required">
	                        	<validation-provider
	                              	#default="{ errors }"
	                              	name="End Date Remarks"
	                              	rules="required"                                  
	                            >   
		                        	<b-form-textarea
		                        		v-model="detail.completion_remark"
		                                placeholder="Brief Description"
		                                rows="3"
		                                :formatter="length1000"
										@change="onChange"
										:disabled="isDisabled"
		                            ></b-form-textarea>
		                            <small class="text-danger">{{ errors[0] }}</small>
		                        </validation-provider>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="12" class="px-0">
	                  		<div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
	                  	</b-col>  			                          	
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Leaders Trained (Direct)" label-cols-lg="4" class="label-break input-group-label">	                        	 
                            	<b-form-input
                            		v-model="detail.number_leader_trained_direct"
                                    placeholder="0"
                                    disabled
                                ></b-form-input>
	                        </b-form-group>
	                  	</b-col> 	     
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Specialists Trained (Direct)" label-cols-lg="4" class="input-group-label">
                            	<b-form-input
                            		v-model="detail.number_trainer_trained_direct"
                                    placeholder="0"
                                    disabled
                                ></b-form-input>
	                        </b-form-group>
	                  	</b-col> 				                          	
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Leaders Trained (Indirect)" label-cols-lg="4" class="label-break input-group-label">
                            	<b-form-input
                            		v-model="detail.number_leader_trained_indirect"
                                    placeholder="0"
                                    disabled
                                ></b-form-input>
	                        </b-form-group>
	                  	</b-col> 	     
	                  	<b-col lg="6" class="px-0">             
	                        <b-form-group label="Specialists Trained (Indirect)" label-cols-lg="4" class="input-group-label">
                            	<b-form-input
                            		v-model="detail.number_trainer_trained_indirect"
                                    placeholder="0"
                                    disabled
                                ></b-form-input>
	                        </b-form-group>
	                  	</b-col> 	
	                  	<b-col lg="12" class="px-0">
	                  		<div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
	                  	</b-col>   
	                  	<b-col lg="12" class="px-0">
	                        <b-form-group label="Project Remarks" label-cols-lg="2">
	                        	<b-form-textarea
	                        		v-model="detail.remarks"
	                                placeholder="Remarks"
	                                rows="3"
	                                :formatter="length1000"
									@change="onChange($event)"
									:disabled="isDisabled"
	                            ></b-form-textarea>
	                        </b-form-group>
	                  	</b-col>  
	              	</b-row>  
	            </div>

	        </b-form> 
	    </validation-observer>
	    <b-col class="export white-bg pt-2 px-0" style="border-radius: 5px">
	    	<b-row class="align-center">
	            <b-col lg="6" style="padding-left: 33px ">
	                <h2 class="mb-2">Project Experts</h2>
	            </b-col>
	            <b-col lg-6>	
	            	<div class="text-right mb-2 pr-2">
	            		<template v-if="(userData && permission.edit)">

		                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="addExpert">
		                        Add Expert
		                    </b-button>

		                </template>
	                    <template v-else>
			            	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
		                        Add Expert
		                    </b-button>
			            </template>
	                </div>
	            </b-col>
	        </b-row>	
	        <b-table small :fields="fields" :items="items" responsive="lg" tbody-tr-class="size-14 table-row" id="export-table" show-empty>
	        	<template #head(id)="data">
		    		<span>{{ data.label }}</span>
		    	</template>
			    <template #head(name)="data">
			        <span>{{ data.label }}</span>
			    </template>
			    <template #head()="data">
			        <span class="d-block text-center">{{ data.label }}</span>
			    </template>
			    <template #cell(name)="data">
			        <span>{{ data.item.name }}</span>
			    </template>
			    <template #cell(id)="data">
					{{ data.item.id }}
				</template>	
				<template #cell(active)="data">
					<span class="d-block text-center" v-if="data.item.active == 0">Inactive</span>
					<span class="d-block text-center" v-else="data.item.active == 1">Active</span>
				</template>									
				<template #cell(action)="data">
					<template v-if="(userData && permission.edit)">
						<span class="d-block text-center cursor-pointer" @click="showDeleteAction(data.item.id, data.item.name)">
							<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#DD3E47"/>
							</svg>
						</span>
					</template>
					<template v-else>
						<span class="d-block text-center">
							<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#B1B1B1"/>
							</svg>
						</span>
					</template>
				</template>
				<template #cell()="data">
					<span class="d-block text-center">{{ data.value }}</span>
				</template>
				<template #empty="scope">
			      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
			    </template>
	        </b-table>
	        <b-modal id="addExpertModal" ref="modal_addExpert" title="Add Expert" hide-header-close ok-title="Save" centered :no-close-on-backdrop="true">
            	<validation-observer ref="addExpert_ref" #default="{invalid}" @close="cancelExpert">
                    <b-row class="mx-auto" style="max-width: 500px">	
                    	<b-col lg="12" class="px-0">
                            <b-form-group label="Expert Type" label-cols-xl="3"
                              label-cols-md="3" label-class="text-lg-right bold">
                              	<validation-provider
                                  #default="{ errors }"
                                  name="Expert Type"
                                  rules="required"                
                                >
	                                <v-select
	                                	v-model="selectedType"
	                                    label="title"
	                                    :options="optionsType"
	                                    placeholder="Select a Type"
	                                    class="timebox black-child-im form-control px-0 border-none"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="12" class="px-0">
                            <b-form-group label="Project Expert" label-cols-xl="3"
                              label-cols-md="3" label-class="text-lg-right bold">
                              	<validation-provider
                                  #default="{ errors }"
                                  name="Project Expert"
                                  rules="required"                
                                >
									<v-select
										v-model="selectedProjectExpert"
	                                    label="eoi_name"
	                                    :options="optionsProject"
	                                    placeholder="Select Expert"
	                                    class="timebox black-child-im form-control px-0 border-none"
	                                />
	                                <small class="text-danger">{{ errors[0] }}</small>
	                            </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>  
                </validation-observer>
                <template #modal-footer="{ cancel }">
                    <b-button size="sm" variant="static" class="btn-default mb-1" @click="cancelExpert">
                      	Cancel
                    </b-button>
                    <b-button size="sm" variant="primary" class="btn-primary mb-1" @click="saveExpert">
                      	Save
                    </b-button>          
                </template>					                
            </b-modal>
	    </b-col>
		<b-modal ref="my-modal" hide-footer title="Unsaved changes" size="lg">
			<div class="d-block">
				<span>You are about to leave this page without saving. All changes will be lost. Do you really want to leave without saving?</span>
			</div>
			<div style="display: flex; justify-content: flex-end; margin-top: 16px">
				<b-button  class="mr-1" variant="outline-danger" @click="hideModalWarning"><span>Leave without saving</span></b-button>
				<b-button  variant="danger" @click="saveChangeModalWarning"><span>Save changes</span></b-button>
			</div>
   		 </b-modal>
	</section>
</template>

<script>

	const moment = require('moment')
	import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	import globalAdmin from '../../../model/globalAdmin'
	import {
		mapActions,
		mapState
	} from 'vuex'
	export default {
		mixins: [globalAdmin],
	  	components: {
	  		flatPickr,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
	  	},
	  	data() {
		    return {
				isCheckChange: false,
				isCheckStartChange: -1,
				isCheckEndChange: -1,
				permission: [],
		    	userData: JSON.parse(localStorage.getItem('userData')),
		    	isEditing: true,
				projectCode: [],
			    codetam: [],
			    selectedCode: null,

			    selectCountry: [], 
			    selectedCountry: null,

			    selectOwner: [],
                selectedOwner: null,

			    selectStatus: [
		            {title: 'Active ', value: 0},  
		            {title: 'Completed', value: 1},          
		            {title: 'Developing', value: 2},
		            {title: 'Terminated', value: 3},
		        ],
		        selectedStatus: {title: 'Select status', value: null},  

		        selectClient: [],
		        selectedClient: null,
		        
		        selectPartner: [],
		        selectedPartner: null,

		        selectCurrency: [],

			    detail: [],

			    // experts
                fields: [
                	{key: 'id', label: 'ID'},
                	{ key: 'name', label: 'Fullname' },
			        { key: 'email', label: 'Email' },			        
			        { key: 'active', label: 'Status' },
			        { key: 'action', label: 'Actions' },
			    ],
		    	items: [],
			    tab_number: null, 

			    optionsType: [
			    	{ title: 'External', value: 0 },
			    	{ title: 'Ex-ITE', value: 1 },
			    	{ title: 'ITE', value: 2 },
			    ],
			    selectedType: null,
			    optionsProject: [],
			    selectedProjectExpert: null,
			    sortExpert: {"sort_field" : "id", "sort_type":"desc"},
				//check number
				isNumber: true,

				isDisabled: true,
            	disableField: 'disabled-field',	
				projectValue: null,
            }
		},
		watch: {
			selectedType:function(val){
				if(val){
					this.selectedProjectExpert = null
					this.projectExperts(val.value)
				}	
				else{
					this.selectedProjectExpert = null
					this.optionsProject = []
				}			
			},
			permissions:function(val){
				this.permission = val
			}
		},
		created(){
			this.permission = this.checkPermissionOurPortfolio(this.userData,"Project",this.$route.params.project_id,"Project") ?? []
			this.detailItem()
			this.getProjectCode()
			this.listAllCountry()
			this.listOwner()
			this.listClient()
			this.listPartner()
			this.listCurrency()
			this.listExpert()
		},

		computed: mapState({
			permissions: state => state.auth.permissionOurPortfolio,
		}),
		methods: {
			...mapActions({
				checkPermisionOurPortfolio: 'auth/checkPermisionOurPortfolio',   
			}),
			onChangeDateStart() {
				this.isCheckStartChange += 1 ;
				if(this.isCheckStartChange !== 0) {
					this.isCheckChange = true;
				}
			},
			onChangeDateEnd() {
				this.isCheckEndChange += 1 ;
				if(this.isCheckEndChange !== 0) {
					this.isCheckChange = true;
				}
			},
			onChange(e) {
				this.isCheckChange = true;
			},
			change() {
				this.isCheckChange = true;
			},
			length1000(e){
	            return String(e).substring(0,1000)
	        },
	        length100(e){
	            return String(e).substring(0,100)
	        },
	        length20(e){
	            return String(e).substring(0,20)
	        },
	        length50(e){
	            return String(e).substring(0,50)
	        },
			validateNumber(event) {
				this.isCheckChange = true;
				const charCode = String.fromCharCode(event.keyCode);
				if (!/[0-9]/.test(charCode) && charCode && event.keyCode != 13 ) {
					event.preventDefault();
					this.isNumber = false;
				}else{
					this.isNumber = true;
				}
			},
			changeProjectValue(event) {
				if(event){
					this.projectValue = +event;
				}
			},
			onRootLostFocus(event) {
				if(this.projectValue){
					var number = +this.projectValue;
					this.projectValue = new Intl.NumberFormat('en-US',{
						style: 'currency',
						currency: 'USD'
					}).format(number)
					this.projectValue = this.projectValue.replace('$', '')
				}
			},
			focusProjectValue(event) {
				if(this.projectValue){
					this.projectValue = this.projectValue.split(',').join("");
					this.projectValue = this.projectValue.split('.00').join("");
				}
			},
          	detailItem(){
          		this.$store
				.dispatch('project/detailProjectList', {
					id: this.$route.params.project_id	
				})
				.then(response => {
					this.detail = response.data.data
					document.title = response.data.data.name + ' - Project Listing - ITEES | Your Global Partner of Choice'	
					if(this.detail.commencement_date) this.isCheckChange === false
					if(this.detail.country_id) this.getCountrybyId(this.detail.country_id)
					if(this.detail.user_id)	this.getOwnerbyId(this.detail.user_id)
					if(this.detail.client) this.getClientbyId(this.detail.client)
					if(this.detail.partner) this.getPartnerbyId(this.detail.partner)

					if(this.detail.status == 0) {
						this.selectedStatus.title = 'Active'
						this.selectedStatus.value = 0
					}
					if(this.detail.status == 1) {
						this.selectedStatus.title = 'Completed'
						this.selectedStatus.value = 1
					}
					if(this.detail.status == 2) {
						this.selectedStatus.title = 'Developing'
						this.selectedStatus.value = 2
					}
					if(this.detail.status == 3) {
						this.selectedStatus.title = 'Terminated'
						this.selectedStatus.value = 3
					}
					this.projectValue = new Intl.NumberFormat('en-US',{
						style: 'currency',
						currency: 'USD'
					}).format(this.detail.amount)
					this.projectValue = this.projectValue.replace('$', '')

				})
				.catch((error) => {
					console.log(error)
				})
          	},
			getProjectCode(){
				this.$store
				.dispatch('auth/fetchProjectCodeList', {	
				})
				.then(response => {
					this.codetam = response.data.data
					this.projectCode = this.codetam.map(
		                (item) => {
		                	return{
		                		id: item.id,
		                		code: item.code
		                	}		                	
		                }
		              )
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getCountrybyId(id){
				this.$store
				.dispatch('auth/detailCountry', {
					id: id
				})
				.then(response => {
					this.selectedCountry = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listAllCountry(){
				this.$store
				.dispatch('auth/fetchCountry',{
					sort: {
						"field": "name",
						"type": "asc"
					}
				})
				.then(response => {
					this.selectCountry = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getOwnerbyId(id){
				this.$store
				.dispatch('auth/detailStaff', {
					id: id
				})
				.then(response => {
					this.selectedOwner = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listOwner(){
				this.$store
				.dispatch('auth/fetch_staff_log_list', {
		            filter: {
		                "group":"1",
						"active":"1"
		            }
		        })
				.then(response => {
					this.selectOwner = response.data.data.data
					// console.log('selectOwner: ', response.data.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getClientbyId(id){
				this.$store
				.dispatch('auth/detailOrganisation', {
					id: id
				})
				.then(response => {
					this.selectedClient = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listClient(){
				this.$store
				.dispatch('auth/Client_Organisation_List', {
		            filter: { 
		            	"list_search": [{ "field": "client","keyword": "1"}]
		        	}
		        })
				.then(response => {
					this.selectClient = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			getPartnerbyId(id){
				this.$store
				.dispatch('auth/detailOrganisation', {
					id: id
				})
				.then(response => {
					this.selectedPartner = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listPartner(){
				this.$store
				.dispatch('auth/Client_Organisation_List', {
		            filter: { 
		            	"list_search": [{ "field": "partner","keyword": "1"}]
		        	}
		        })
				.then(response => {
					this.selectPartner = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listCurrency(){
				this.$store
				.dispatch('project/getCurrencyList')
				.then(response => {
					this.selectCurrency = response.data.data
					// console.log('listCurrency: ', response.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			cancelAction() {
				if(this.isCheckChange) {
					this.$refs['my-modal'].show()					
				}
				else {
					if(this.$route.params && this.$route.params.id) {
                	this.$router.push({ path: `/admin/our-portfolio/project/${this.$route.params.id}` })
					}
					else {
						this.$router.push({ name: 'our-portfolio-project' })
					}
				}
			},
			hideModalWarning() {
				this.$refs['my-modal'].hide()
				if(this.$route.params && this.$route.params.id) {
						this.$router.push({ path: `/admin/our-portfolio/project/${this.$route.params.id}` })
					}
					else {
						this.$router.push({ name: 'our-portfolio-project' })
				}
			},
			saveChangeModalWarning() {
				this.$refs['my-modal'].hide()
				this.$refs.form_rel.validate().then(success => {
					if(success){
						this.projectValue = this.projectValue.split(',').join("");
						this.projectValue = this.projectValue.split('.00').join("");
						let list = {
		                    name: this.detail.name,
		                    description: this.detail.description,
		                    country_id: this.selectedCountry ? this.selectedCountry.id : null,
		                    province_state_city: this.detail.province_state_city,
		                    status: this.selectedStatus.value,
		                    amount: this.projectValue, //project value
		                    user_id: this.selectedOwner ? this.selectedOwner.id : null,
		                    remarks: this.detail.remarks,
		                    commencement_date: this.detail.commencement_date,
		                    commencement_remark: this.detail.commencement_remark,
		                    completion_date: this.detail.completion_date,
		                    completion_remark: this.detail.completion_remark,
		                    currency_name: this.detail.currency_name,
		                    project_code: this.detail.project_code ? this.detail.project_code.id : null,
		                    client: this.selectedClient ? this.selectedClient.id : null,
		                    partner: this.selectedPartner ? this.selectedPartner.id : null,
		                }
		                if(this.selectedStatus.value == 0){
		                	this.tab_number = 1
		                }
		                else if(this.selectedStatus.value == 1){
		                	this.tab_number = 2
		                }
		                else if(this.selectedStatus.value == 2){
		                	this.tab_number = 3
		                }
		                else if(this.selectedStatus.value == 3){
		                	this.tab_number = 4
		                }

		                // console.log('data: ', list)
		                this.$store
		                .dispatch('project/editProjectList', 
		                {
		                    id: this.$route.params.project_id,
		                    data: list
		                })
		                .then(response => {
		                    this.$toast({
		                        component: ToastificationContent,
		                        props: {
		                          title: this.detail.name + ' Updated!',
		                          variant: 'success',
		                        },
		                      },
		                      {
		                        position: 'top-center'
		                      }
		                    )
	                    	if(this.$route.params && this.$route.params.id) {
		                    	this.$router.push({ path: '/admin/our-portfolio/project/' + this.selectedCountry.id, query: { tab: this.tab_number }})
		                    }
		                    else {
		                    	this.$router.push({ name: 'our-portfolio-project', query: { tab: this.tab_number } })
		                	}
		                })
		                .catch((error) => {
		                    console.log(error)
		                    this.$toast({
		                        component: ToastificationContent,
		                        props: {
		                          title: error.response.data.message,
		                          variant: 'danger',
		                        },
		                      },
		                      {
		                        position: 'top-center'
		                      }
		                    )
		                })  
					}
					else{
		            	 this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
		            }
				})
			},
			editAction(){
	          	this.isEditing = false
	          	this.isDisabled = false
	          	this.disableField = ''
			},
			saveAction(){
				this.$refs.form_rel.validate().then(success => {
					if(success){
						this.projectValue = this.projectValue.split(',').join("");
						this.projectValue = this.projectValue.split('.00').join("");
						let list = {
		                    name: this.detail.name,
		                    description: this.detail.description,
		                    country_id: this.selectedCountry ? this.selectedCountry.id : null,
		                    province_state_city: this.detail.province_state_city,
		                    status: this.selectedStatus.value,
		                    amount: this.projectValue, //project value
		                    user_id: this.selectedOwner ? this.selectedOwner.id : null,
		                    remarks: this.detail.remarks,
		                    commencement_date: this.detail.commencement_date,
		                    commencement_remark: this.detail.commencement_remark,
		                    completion_date: this.detail.completion_date,
		                    completion_remark: this.detail.completion_remark,
		                    currency_name: this.detail.currency_name,
		                    project_code: this.detail.project_code ? this.detail.project_code.id : null,
		                    client: this.selectedClient ? this.selectedClient.id : null,
		                    partner: this.selectedPartner ? this.selectedPartner.id : null,
		                }
		                if(this.selectedStatus.value == 0){
		                	this.tab_number = 1
		                }
		                else if(this.selectedStatus.value == 1){
		                	this.tab_number = 2
		                }
		                else if(this.selectedStatus.value == 2){
		                	this.tab_number = 3
		                }
		                else if(this.selectedStatus.value == 3){
		                	this.tab_number = 4
		                }

		                // console.log('data: ', list)
		                this.$store
		                .dispatch('project/editProjectList', 
		                {
		                    id: this.$route.params.project_id,
		                    data: list
		                })
		                .then(response => {
		                    this.$toast({
		                        component: ToastificationContent,
		                        props: {
		                          title: this.detail.name + ' Updated!',
		                          variant: 'success',
		                        },
		                      },
		                      {
		                        position: 'top-center'
		                      }
		                    )
	                    	if(this.$route.params && this.$route.params.id) {
		                    	this.$router.push({ path: '/admin/our-portfolio/project/' + this.selectedCountry.id, query: { tab: this.tab_number }})
		                    }
		                    else {
		                    	this.$router.push({ name: 'our-portfolio-project', query: { tab: this.tab_number } })
		                	}
		                })
		                .catch((error) => {
		                    console.log(error)
		                    this.$toast({
		                        component: ToastificationContent,
		                        props: {
		                          title: error.response.data.message,
		                          variant: 'danger',
		                        },
		                      },
		                      {
		                        position: 'top-center'
		                      }
		                    )
		                })  
					}
					else{
		            	 this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
		            }
				})
			},
			listExpert(){
				this.$store
				.dispatch('project/listExpertsAssign', {
					filter: { "project": this.$route.params.project_id },
					sort: this.sortExpert
				})
				.then(response => {
					// console.log('listExpert: ', response.data.data)
					this.items = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			addExpert(){
				this.$refs['modal_addExpert'].show()	
			},
			cancelExpert(){
				this.$refs['modal_addExpert'].hide()	
				this.selectedType = null
				this.selectedProjectExpert = null
			},
			saveExpert(){
				this.$refs.addExpert_ref.validate().then(success => {
					if (success){
						let datas = {
							expert_id: this.selectedProjectExpert ? this.selectedProjectExpert.id : null,
							project_id: this.$route.params.project_id
						}
						this.$store
						.dispatch('project/addExperts', datas)
						.then(response => {
							this.$refs['modal_addExpert'].hide()
							this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                          title: 'Expert Added!',
			                          variant: 'success',
			                      	},
			                	},
			                	{
			                      	position: 'top-center'
			                	}
			                )	
			                this.listExpert()
						})
						.catch((error) => {
							console.log(error)
							this.$toast({
								component: ToastificationContent,
								props: {
									title: error.response.data.message,
									variant: 'danger',
								},
							},
							{
							position: 'top-center'
							})
						})
					}
				})				
			},
			projectExperts(type){
				this.$store
				.dispatch('project/listExperts', {
					filter: 
					{
					  "list_search": [
					    {
					      "field": "status",
					      "keyword": 2
					    },{
					      "field": "type",
					      "keyword": type
					    },
					  ]
					}
				})
				.then(response => {
					this.optionsProject = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			showDeleteAction(id, title) {
		        this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
		          title: 'Delete',
		          size: 'sm',
		          buttonSize: 'sm',
		          okVariant: 'danger',
		          okTitle: 'Delete',
		          cancelTitle: 'Cancel',
				  cancelVariant: 'outline-danger',
		          footerClass: 'p-2 modal-delete',
		          hideHeaderClose: false,
		          centered: true
		        })
		          .then(value => {
		            	if(value){
		            		this.deleteItem(id, title)	
		            	}
		          })
		          .catch(err => {
		            // An error occurred
		          })
		    },
		    deleteItem(id, title){
		    	this.$store
				.dispatch('project/deleteExpertItem', {
					id: id	
				})
				.then(response => {
					this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                          title: title + ' Has been deleted!',
	                          variant: 'danger',
	                      	},
	                	},
	                	{
	                      	position: 'top-center'
	                	}
	                )
	                this.listExpert()	                			
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
				})
		    },
		}
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";

	#addExpertModal .modal-dialog{
		max-width: 600px;
	}
	.gift-table table thead tr th:first-child{
		min-width: 61px
	}
	.gift-table table thead tr th:last-child{
		min-width: 110px
	}
	#addExpertModal .modal-dialog .modal-header{
		border-bottom: 1px solid rgba(0, 0, 0, 0.7);
	}
	#addExpertModal .modal-dialog .row-container{
		padding-right: 80px;
    	padding-left: 50px;
	}
	#addExpertModal .modal-dialog .modal-body{
		padding-top: 18px;
    	padding-bottom: 0;
	}
	#addExpertModal .modal-dialog .modal-footer{
		padding-bottom: 18px;
		padding-top: 15px 
	}
	.tab-pt-0 .px-table-0 > .card-body {
	    padding-top: 0;
	}
</style>