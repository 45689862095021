<template>
	<section class="py-modal-body-0">
		<b-tabs card class="caption-add table-custom">
			<b-tab title="Participants">
				<b-card
					class="px-table-0 mt-1 w-100-cl-1 w-17-percent-cl-2 w-17-percent-cl-5 w-17-percent-cl-3 w-17-percent-cl-4 w-15-percent-cl-5 wrap-anywhre">
					<b-table small :fields="fields_users" :items="users" tbody-tr-class="size-14 table-row cursor-pointer"
						show-empty @head-clicked="headClicked" @row-clicked="detailParticipant">
						<template #head(id)="data">
							<span>{{ data.label }}</span>
							<span
								:class="['cursor-pointer iconsort', { sort: data.field.key == user_ex_sort.sort_field && user_ex_sort.sort_type == 'desc' }]"
								style="position: relative; left: 15px;">
								<svg width="16" height="16" viewBox="0 0 16 16" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2"
										stroke-linecap="round" stroke-linejoin="round" />
									<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" />
									<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" />
									<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" />
									<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round"
										stroke-linejoin="round" />
								</svg>
							</span>
						</template>
						<template #head()="data">
							<span class="d-block text-center">{{ data.label }}</span>
						</template>
						<template #head(name)="data">
							<span>{{ data.label }}</span>
						</template>
						<template #head(status)="data">
							<span class="d-block text-center" >
								{{ data.label }}
							</span>
						</template>
						<template #cell(id)="data">
							<span>{{ data.item.id }}</span>
						</template>
						<template #cell(name)="data">
							<span class="maxTwoRow">{{ data.item.name }}</span>
						</template>
						<template #cell(organisations)="data">
							<span class="organisations">{{ data.item.organisations }}</span>
						</template>
						<template #cell(status)="data">
							<span class="d-block text-center" v-if="data.item.status == 0">Inactive</span>
							<span class="d-block text-center" v-else="data.item.status == 1">Active</span>
						</template>
						<template #cell(activities)="data">
							<div class="d-block text-center">
								<div class="text-center d-block cursor-pointer decoration-hover"
									@click.stop="showList(data.item.user_id)" style="color: #8471FF">
									List
								</div>
							</div>
						</template>
						<template #cell()="data">
							<span class="d-block text-center">{{ data.value }}</span>
						</template>
						<template #empty="scope">
							<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No
								data</h4>
						</template>
					</b-table>
				</b-card>
				<div v-if="this.total > 0" class="paging-cus relative">
					<b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage" aria-controls="active-table"
						first-number last-number class="just-center"></b-pagination>
					<div class="limit-per">
						<select v-model="selected" class="cursor-pointer">
							<option v-for="limit in limitpage">{{ limit }}</option>
						</select>
					</div>
				</div>
			</b-tab>
			<b-tab title="Experts">
				<expertTab />
			</b-tab>
		</b-tabs>
		<b-modal id="modalListUser" ref="modal_Users" title="Edit Gift" hide-header ok-title="Save" centered>
			<div class="table-custom">
				<b-table small :fields="fields_participants_list" :items="participants_list"
					tbody-tr-class="size-14 table-row" show-empty>
					<template #head(action)="data">
						<span class="d-block text-center">{{ data.label }}</span>
					</template>
					<template #cell(no)="data">
						{{ data.index + 1 }}
					</template>
					<template #cell(action)="data">
						<b-link
							:href="`/admin/our-portfolio/project/${$route.params.id}/edit/${$route.params.project_id}/activity/${data.item.activity_id}`"
							target="_blank" class="d-block text-center">
							<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M13.501 16H2.50098C1.39641 16 0.500977 15.1046 0.500977 14V3C0.500977 1.89543 1.39641 1 2.50098 1H6.50098V3H2.50098V14H13.501V10H15.501V14C15.501 15.1046 14.6055 16 13.501 16ZM8.20098 9.707L6.79098 8.293L13.084 2H9.50098V0H16.501V7H14.501V3.415L8.20098 9.707Z"
									fill="#8471FF" />
							</svg>
						</b-link>
					</template>
					<template #empty="scope">
						<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data
						</h4>
					</template>
				</b-table>
			</div>
			<template #modal-footer="{ cancel }">
				<b-button size="sm" variant="static" class="btn-default" @click="cancel()">
					Close
				</b-button>
			</template>
		</b-modal>
	</section>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import expertTab from './experts/List'
export default {
	components: {
		expertTab
	},
	data() {
		return {
			fields_users: [
				{ key: 'id', label: 'ID.' },
				{ key: 'name', label: 'Fullname' },
				{ key: 'email', label: 'Email' },
				{ key: 'organisations', label: 'Organisation' },
				{ key: 'position', label: 'Position Level' },
				{ key: 'status', label: 'User Status' },
				{ key: 'activities', label: 'Activities' },
			],
			users: [],

			fields_participants_list: [
				{ key: 'no', label: 'No.' },
				{ key: 'activity_name', label: 'Activity Listing' },
				{ key: 'action', label: 'Actions' },
			],
			participants_list: [],
			userData: JSON.parse(localStorage.getItem('userData')),

			user_ex_sort: { "sort_field": "id", "sort_type": "asc" },
			perPage: 10,
			currentPage: 1,
			total: 0,
			limitpage: ['10', '20', '30'],
			selected: '10',
		}
	},
	mounted() {
		this.selected = '10'
	},
	watch: {
		currentPage(val) {
			this.fetchList()
		},
		selected(val) {
			if (val) {
				this.perPage = val
				this.fetchList()
			}
		},
	},
	created() {
		this.fetchList()
	},
	methods: {
		fetchList() {
			this.$store
				.dispatch('project/listUsers', {
					filter: { "project_activity_id": this.$route.params.project_id },
					sort: this.user_ex_sort,
					limit: this.perPage,
					page: this.currentPage,
				})
				.then(response => {
					this.users = response.data.data.data
					this.total = response.data.data.total
					// console.log('User participant: ', response.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		headClicked(key) {
			if (this.user_ex_sort.sort_field == key) {
				if (this.user_ex_sort.sort_type == 'asc') this.user_ex_sort.sort_type = 'desc'
				else this.user_ex_sort.sort_type = 'asc'
			} else {
				this.user_ex_sort.sort_field = key
				this.user_ex_sort.sort_type = 'desc'
			}
			this.fetchList()
		},
		showDeleteAction(id, title) {
			this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
				title: 'Delete',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Delete',
				cancelTitle: 'Cancel',
				cancelVariant: 'outline-danger',
				footerClass: 'p-2 modal-delete',
				hideHeaderClose: false,
				centered: true
			})
				.then(value => {
					if (value) {
						this.deleteItem(id, title)
					}
				})
				.catch(err => {
					// An error occurred
				})
		},
		deleteItem(id, title) {
			this.$store
				.dispatch('project/deleteUserAssign', {
					id: id
				})
				.then(response => {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: title + ' Has been deleted!',
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						}
					)
					this.fetchList()
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						}
					)
				})
		},
		showList(id) {
			this.listUsers(id)
			this.$refs['modal_Users'].show()
		},
		listUsers(id) {
			this.$store
				.dispatch('project/listActivityParticipant', {
					user_id: id,
					project_id: this.$route.params.project_id
				})
				.then(response => {
					this.participants_list = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
		},
		detailParticipant(item) {
			this.$router.push(`/admin/participant-management/${item.user_id}`)
		}
	}
}
</script>
<style lang="scss">.Vue-Toastification__container.top-center {
	top: 20px;
}

.organisations {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	text-align: center;
}</style>