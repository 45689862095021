<template>
	<section>
		<b-card class="px-table-0 mt-1 w-15-percent-cl-1 w-20-percent-cl-2 w-25-percent-cl-3">
			<b-table small :fields="fields_documents" :items="documents" tbody-tr-class="size-14 table-row" show-empty
				@head-clicked="headClicked">
				<template #head(id)="data">
					<span>{{ data.label }}</span>
					<span
						:class="['cursor-pointer iconsort', { sort: data.field.key == sort.field && sort.type == 'desc' }]"
						style="position: relative; left: 40px;">
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2"
								stroke-linecap="round" stroke-linejoin="round" />
							<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round"
								stroke-linejoin="round" />
							<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round"
								stroke-linejoin="round" />
						</svg>
					</span>
				</template>
				<template #head()="data">
					<span class="d-block text-center">{{ data.label }}</span>
				</template>
				<template #head(attach_file_title)="data">
					<span>{{ data.label }}</span>
				</template>
				<template #cell(attach_file_title)="data">
					<span>{{ data.item.attach_file_title }}</span>
				</template>
				<template #cell(attach_content_type)="data">
					<span class="maxThreeRow text-center">
						<template v-if="data.item.attach_content_type == null || data.item.attach_content_type == 'null'">
							-
						</template>
						<template v-else>
							{{ data.item.attach_content_type }}
						</template>
					</span>
				</template>
				<template #cell(updated_at)="data">
					<span class="d-block text-center">{{ data.item.updated_at | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY")
					}}</span>
				</template>
				<template #cell(id)="data">
					<span v-if="data.field.key == sort.field && sort.type == 'desc'">
						<template v-if="selected == 10">
							{{ ((total - data.index) - ((currentPage - 1) * 10)) }}
						</template>
						<template v-else-if="selected == 20">
							{{ ((total - data.index) - ((currentPage - 1) * 20)) }}
						</template>
						<template v-else>
							{{ ((total - data.index) - ((currentPage - 1) * 30)) }}
						</template>
					</span>
					<span v-else>
						<template v-if="selected == 10">
							{{ ((currentPage - 1) * 10) + (data.index + 1) }}
						</template>
						<template v-else-if="selected == 20">
							{{ ((currentPage - 1) * 20) + (data.index + 1) }}
						</template>
						<template v-else>
							{{ ((currentPage - 1) * 30) + (data.index + 1) }}
						</template>
					</span>
				</template>
				<template #cell(action)="data">
					<div class="d-block text-center">
						<span class="px-05 cursor-pointer"
							@click.stop="downloadDocument(data.item.attach_file_name, data.item.attach_file_title)">
							<feather-icon icon="DownloadIcon" size="22" />
						</span>
						<template v-if="(userData && (permission ? permission.edit : false))">

							<span class="px-05 cursor-pointer"
								@click.stop="editDocument(data.item.id, data.item.attach_file_title, data.item.attach_file_name, data.item.attach_content_type)">
								<svg width="19" height="19" viewBox="0 0 19 19" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
										fill="#8471FF" />
								</svg>
							</span>
							<template v-if="userData">
								<span class="px-05 cursor-pointer"
									@click.stop="showDeleteAction(data.item.id, data.item.attach_file_title)">
									<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
											fill="#DD3E47" />
									</svg>
								</span>
							</template>
							<template v-else>
								<span class="px-05">
									<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" clip-rule="evenodd"
											d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
											fill="#b1b1b1" />
									</svg>
								</span>
							</template>
						</template>
						<template v-else>
							<span class="px-05">
								<svg width="19" height="19" viewBox="0 0 19 19" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z"
										fill="#b1b1b1" />
								</svg>
							</span>
							<span class="px-05">
								<svg width="14" height="18" viewBox="0 0 14 18" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd"
										d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z"
										fill="#b1b1b1" />
								</svg>
							</span>
						</template>
					</div>
				</template>
				<template #cell()="data">
					<span class="d-block text-center">{{ data.value }}</span>
				</template>
				<template #empty="scope">
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
				</template>
			</b-table>
		</b-card>
		<div v-if="this.total > 0" class="paging-cus relative">
			<b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage" aria-controls="active-table"
				first-number last-number class="just-center"></b-pagination>
			<div class="limit-per">
				<select v-model="selected" class="cursor-pointer">
					<option v-for="limit in limitpage">{{ limit }}</option>
				</select>
			</div>
		</div>
		<b-modal id="editDocumentPopup" ref="modal_editDocument" title="Edit Document" hide-header-close ok-title="Save"
			centered>
			<validation-observer ref="editDocument_ref" #default="{ invalid }">

				<b-row style="padding-right: 30px">
					<b-col lg="12" class="px-0">
						<b-form-group label="Name" label-cols-xl="3" label-cols-md="3" label-class="text-lg-right bold"
							class="star-required input-group-label">
							<validation-provider #default="{ errors }" name="Title" vid="name" rules="required">
								<b-form-input v-model="fieldsDocumentShow.name" placeholder="Title" type="text" col-md="7"
									:formatter="length100"></b-form-input>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<b-col lg="12" class="px-0">
						<b-form-group label="File Upload" label-cols-lg="3"
							class="uploadImage text-lg-right label-bold star-required">
							<div class="upload-show-name container-box px-1" v-if="fieldsDocumentShow.file"
								style="flex-direction: column; word-break: break-all">
								<div class="close-upload cursor-pointer" @click="closeEditDocumentUpload">
									<feather-icon icon="XCircleIcon" size="24" color="#DD3E47" />
								</div>
								<p>
									<feather-icon icon="ImageIcon" size="30" />
								</p>
								{{ fieldsDocumentShow.file.name ? fieldsDocumentShow.file.name : fieldsDocumentShow.file }}
							</div>
							<div v-else>
								<validation-provider #default="{ errors }" name="File Upload" vid="file" rules="required">
									<div class="bg container-box"
										:style="{ backgroundImage: `url(${require('@/assets/images/itees/admin/UploadFile.png')})` }">
										<b-form-file v-model="fieldsDocumentShow.file" plain
											accept=".jpg, jpeg, .png, .zip, .mp4, .pdf, .doc, .docx, .bin, xlsx, pptx, gif"
											ref="fileImage" class="cursor-pointer">
										</b-form-file>
									</div>
									<small class="text-danger text-left d-block">{{ errors[0] }}</small>
								</validation-provider>
							</div>
						</b-form-group>
					</b-col>
					<b-col lg="12" class="px-0">
						<b-form-group label="Remarks" label-cols-xl="3" label-cols-md="3" label-class="text-lg-right bold">
							<b-form-textarea v-model="fieldsDocumentShow.remarks" placeholder="Remarks" rows='4'
								:formatter="length1000"></b-form-textarea>
						</b-form-group>
					</b-col>
				</b-row>
			</validation-observer>
			<template #modal-footer="{ cancel }">
				<b-button size="sm" variant="static" class="btn-default mb-1" @click="cancel()">
					Cancel
				</b-button>
				<b-button size="sm" variant="primary" class="btn-primary mb-1" @click="saveEditDocument">
					Save
				</b-button>
			</template>
		</b-modal>
	</section>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, integer, min } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
	components: {
		ValidationProvider,
		ValidationObserver,
	},
	props: {
		permission: {
			type: [Array, String, Number, Object],
		}
	},
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			labels: { "our-portfolio-project-detail-edit-blank": "" },
			fieldAdd: {
				name: null,
				file: null,
				remarks: null
			},
			sort: {
				field: 'id',
				type: 'asc'
			},
			perPage: 10,
			currentPage: 1,
			total: 0,
			limitpage: ['10', '20', '30'],
			selected: '10',

			fields_documents: [
				{ key: 'id', label: 'No.' },
				{ key: 'attach_file_title', label: 'Document Name' },
				{ key: 'attach_content_type', label: 'Remarks' },
				{ key: 'updated_at', label: 'Uploaded Date' },
				{ key: 'action', label: 'Actions' },
			],
			documents: [],
			fieldsDocumentShow: {
				id: null,
				name: null,
				file: null,
				remarks: ''
			},
		}
	},
	mounted() {
		this.selected = '10'
	},
	watch: {
		currentPage(val) {
			this.featctList()
		},
		selected(val) {
			if (val) {
				this.perPage = val
				this.featctList()
			}
		},
	},
	created() {
		this.featctList()
	},
	methods: {
		featctList() {
			this.$store
				.dispatch('auth/fetchDocumentInfo', {
					filter: {
						"all_document_activity_of_project": this.$route.params.project_id
					},
					limit: this.perPage,
					page: this.currentPage,
					sort: this.sort,
				})
				.then(response => {
					this.documents = response.data.data.data
					this.total = response.data.data.total
					// console.log('list: ', response.data.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
		},
		closeUpload() {
			this.fieldAdd.file = null
		},
		saveAction() {
			this.$refs.form_rel.validate().then(success => {

				if (success) {
					let addDocument = {
						attachable_id: this.$route.params.project_id,
						name: this.fieldAdd.name,
						file: this.fieldAdd.file,
						remarks: this.fieldAdd.remarks,
						attachable_type: 'project',
					}
					// console.log('data: ', addDocument)
					this.$store
						.dispatch('auth/addDocument', addDocument)
						.then(response => {
							this.$refs['addPopup_rel'].hide()
							this.$toast({
								component: ToastificationContent,
								props: {
									title: this.fieldAdd.name + ' Added!',
									variant: 'success',
								},
							},
								{
									position: 'top-center'
								})
							this.featctList()
							this.fieldAdd.file = null
							this.fieldAdd.name = null
							this.fieldAdd.remarks = null
						})
						.catch((error) => {
							console.log(error)
							this.$toast({
								component: ToastificationContent,
								props: {
									title: error.response.data.message,
									variant: 'danger',
								},
							},
								{
									position: 'top-center'
								}
							)
						})
				}

			})
		},
		editDocument(id, name, path, remarks) {

			var path_name = path.split("/")[path.split("/").length - 1]
			var format_name = name.split('.', length - 1)

			this.$refs['modal_editDocument'].show()
			this.fieldsDocumentShow.name = format_name[0]
			this.fieldsDocumentShow.id = id
			this.fieldsDocumentShow.file = path_name
			if (remarks == 'null' || remarks == '-') {
				this.fieldsDocumentShow.remarks = ''
			}
			else {
				this.fieldsDocumentShow.remarks = remarks
			}
		},
		saveEditDocument() {
			this.$refs.editDocument_ref.validate().then(success => {

				if (success) {

					var fileSize_true = null

					if (this.fieldsDocumentShow.file.size && this.fieldsDocumentShow.file.size > 52428800) {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Uploaded file is too large, maximum 50MB',
								variant: 'danger',
							},
						},
							{
								position: 'top-center'
							}
						)
					}
					else {
						let documentField = {}
						if (this.fieldsDocumentShow.file && this.fieldsDocumentShow.file.name) {
							documentField = {
								name: this.fieldsDocumentShow.name,
								file: this.fieldsDocumentShow.file,
								remarks: this.fieldsDocumentShow.remarks,
							}
						}
						else {
							documentField = {
								name: this.fieldsDocumentShow.name,
								remarks: this.fieldsDocumentShow.remarks,
							}
						}
						this.$store
							.dispatch('auth/editDocument',
								{
									id: this.fieldsDocumentShow.id,
									data: documentField
								}
							)
							.then(response => {
								this.$refs['modal_editDocument'].hide()
								this.$toast({
									component: ToastificationContent,
									props: {
										title: this.fieldsDocumentShow.name + ' Updated!',
										variant: 'success',
									},
								},
									{
										position: 'top-center'
									}
								)
								this.featctList()
							})
							.catch((error) => {
								console.log(error.response)
								this.$toast({
									component: ToastificationContent,
									props: {
										title: error.response.data.message,
										variant: 'danger',
									},
								},
									{
										position: 'top-center'
									})
							})
					}
				}
			})
		},
		closeEditDocumentUpload() {
			this.fieldsDocumentShow.file = null
		},
		showDeleteAction(id, title) {
			this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
				title: 'Delete',
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'danger',
				okTitle: 'Delete',
				cancelTitle: 'Cancel',
				cancelVariant: 'outline-danger',
				footerClass: 'p-2 modal-delete',
				hideHeaderClose: false,
				centered: true
			})
				.then(value => {
					if (value) {
						this.deleteItem(id, title)
					}
				})
				.catch(err => {
					// An error occurred
				})
		},
		deleteItem(id, title) {
			this.$store
				.dispatch('auth/deleteDocument', {
					id: id
				})
				.then(response => {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: title + ' Has been deleted!',
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						})
					this.featctList()
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						}
					)
				})
		},
		length100(e) {
			return String(e).substring(0, 100)
		},
		length1000(e) {
			return String(e).substring(0, 1000)
		},
		headClicked(key) {
			if (this.sort.field == key) {
				if (this.sort.type == 'asc') this.sort.type = 'desc'
				else this.sort.type = 'asc'
			} else {
				this.sort.field = key
				this.sort.type = 'desc'
			}
			this.featctList()
		},

		downloadDocument(path, title) {
			const url = `${process.env.VUE_APP_SERVER_URL}/api/downLoad?url_file=${path}`
			axios({
				method: 'get',
				url: url,
				responseType: 'blob',
			})
				.then(response => {
					this.forceFileDownload(response, title)
				})
				.catch(() => console.log('error occured'))
		},
		forceFileDownload(response, namFile) {
			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', namFile)
			document.body.appendChild(link)
			link.click()
		},
	}
};

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "@/assets/scss/_ite_tab_table.scss";

.tab-pt-0 .px-table-0>.card-body {
	padding-top: 0;
}
</style>